<template>
  <BT-Blade-Item
    bladeName="packing"
    :bladesData="bladesData"
    :canDelete="false"
    :canEdit="false"
    :changeToggle="changeToggle"
    flexColumn
    :loading="isLoading"
    navigation="customer-orders"
    title="Pack Consignment">
    <template v-slot="{ item }">
        <BT-Snack v-model="msg" />

        <v-row dense>
          <v-col cols="6">
            <BT-Field-Object
                v-model="item.buyer"
                itemText="companyName"
                label="Customer" />
          </v-col>
          <v-col cols="6">
              <BT-Field-Address
                  v-model="item.location"
                  label="Destination" />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="6">
              <BT-Field-Date
                  v-model="item.dueDate"
                  label="Due Date"
                  shortDateAndTime />
          </v-col>
          <v-col cols="6">
            <BT-Field-String
              v-model="item.customerOrderNumber"
              label="CO#" />
          </v-col>
        </v-row>

        <v-row v-if="currentStockConsignment != null && currentStockConsignment.packedOn != null" dense>
          <v-col cols="6">
            <BT-Field-Date
              v-model="currentStockConsignment.packedOn"
              label="Packed On" />
          </v-col>
          <v-col cols="6">
            <BT-Field-Select
              navigation="users"
              v-model="currentStockConsignment.packedByUserID"
              itemText="userName"
              label="Packed By" />
          </v-col>
        </v-row>

      <v-row>
        <v-col cols="12" md="6">
          <v-expansion-panels v-model="panelV" multiple>
            <BT-Blade-Expansion-Items
              v-if="item != null"
              :canExportCSV="false"
              :canRefresh="false"
              :canSelect="false"
              :headers="[
                { text: 'QTY', value: 'quantity', textFilter: 'toDisplayNumber', display: true, subtitle: 1, prefix: 'QTY: ' },
                { text: 'Description', value: 'description', display: true, title: 1 }]"
              hideActions
              :items="item.orderItems"
              :onFilter="list => { return showAll ? list : list.filter(x => x.placeholderProductID != null) }"
              showTable
              title="Ordered Items">
              <template v-slot:actions>
                  <v-btn @click.stop="showAll = !showAll" small icon>
                      <v-icon small>mdi-filter</v-icon>
                  </v-btn>
              </template>
              <template v-slot:quantity="{ item }">
                  <span>{{ item.quantity | toDisplayNumber }}</span>
              </template>
              <template v-slot:description="{ item }">
                  <span>{{ item.description }}</span>
              </template>
            </BT-Blade-Expansion-Items>
            <BT-Blade-Expansion-Items
              v-if="!showAll && item != null"
              :canExportCSV="false"
              :canRefresh="false"
              :canSelect="false"
              :headers="[
                { text: 'QTY', value: 'quantity', textFilter: 'toDisplayNumber', display: true, subtitle: 1, prefix: 'QTY: ' },
                { text: 'Description', value: 'description', title: 1 }]"
              hideActions
              :items="item.orderItems"
              :onFilter="list => { return showAll ? list : list.filter(x => x.isPlaceholder) }"
              showTable
              title="Placeholder Lines">
              <template v-slot:quantity="{ item }">
                <BT-Increment-Quantity-Edit
                  v-model="item.quantity"
                  :isEditing="isPacking"
                  :productID="item.productID" />
              </template>
            </BT-Blade-Expansion-Items>
          </v-expansion-panels>
        </v-col>
        <v-col cols="12" md="6">
          <v-expansion-panels v-model="panelVI">
            <BT-Blade-Button
              v-if="!isPacking"
              label="Start Packing"
              @click="startPack(item)" />
            <BT-Blade-Expansion-Items
              v-if="currentStockConsignment != null && item != null"
              :canExportCSV="false"
              :canRefresh="false"
              :canSelect="false"
              hideActions
              :items="currentStockConsignment.consignmentEntryItems"
              :onFilter="list => { return showAll ? list : list.filter(x => item.orderItems.filter(y => y.placeholderProductID != null || y.isPlaceholder).some(z => z.productID == x.productID)) }"
              showList
              title="Stock Items"
              three-line>
              <template v-slot:actions>
                  <Stock-Assign-Dialog
                    v-if="currentStockConsignment.performedOn == null && currentStockConsignment.departureLocationID != null"
                    buttonClass="primary"
                    small
                    :consignment="currentStockConsignment"
                    text="Assign Stock" />
                  <v-btn @click.stop="showAll = !showAll" small icon>
                      <v-icon small>mdi-filter</v-icon>
                  </v-btn>
              </template>
              <template v-slot:listItem="{ item }">
                  <v-list-item-content>
                      <v-list-item-title v-if="item.product != null">{{ item.product.abbreviation }} - {{ item.product.productName }}</v-list-item-title>
                      <v-list-item-subtitle v-if="item.batch != null">DOM: {{ item.batch.dom | toShortDate }} | EXP: {{ item.batch.exp | toShortDate }}</v-list-item-subtitle>
                      <v-list-item-subtitle>
                          <v-row dense>
                              <v-col cols="6">
                                  <span>Pick: {{ item.quantityPicked | toDisplayNumber }}</span>
                              </v-col>
                              <v-col cols="6">
                                  <span>Assign: {{ item.quantity | toDisplayNumber }}</span>
                              </v-col>
                          </v-row>
                      </v-list-item-subtitle>
                  </v-list-item-content>
              </template>
          </BT-Blade-Expansion-Items>
          
          <v-btn 
            v-if="isPacking"
            @click="packAndSave(item)"
            block 
            class="primary">
            Pack & Save
          </v-btn>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </template>
  </BT-Blade-Item>
</template>

<script>
import { firstBy } from 'thenby';
import { updateOrderWithoutUI } from '~helpers';

export default {
    name: 'Packing-Blade',
    components: {
      StockAssignDialog: () => import('~home/stock-consignments/Stock-Assign-Dialog.vue'),
      BTIncrementQuantityEdit: () => import('~components/BT-Increment-Quantity-Edit.vue'),
    },
    data: function() {
      return {
        changeToggle: false,
        currentCustomerOrder: null,
        currentPolicy: null,
        currentStockConsignment: null,
        isLoading: false,
        isPacking: false,
        msg: null,
        panelV: [0, 1],
        panelVI: 0,
        panelVII: 0,
        placeholderLines: [],
        showAll: false,
      }
    },
    props: {
        bladesData: null
    },
    methods: {
        async startPack(po) {
            try {
                this.showAll = false;
                this.isPacking = true;
                this.isLoading = true;

                this.currentStockConsignment = await this.$BlitzIt.store.get('stock-consignments', po.id);

                if (this.isLengthyArray(po.orderItems)) {
                  //pull policy
                  po.orderItems.sort(firstBy(x => x.sortNumber));
console.log('getting policy ' + po.supplyAgreementID);
                  this.currentPolicy = await this.$BlitzIt.store.get('suppliers', po.supplyAgreementID, null, null, null, '/get/Policy');

                  if (this.currentPolicy == null) {
                    this.msg = 'Unable to find policy';
                    this.showAll = false;
                    this.isPacking = true;
                    this.isLoading = true;
                    return;
                  }
                  var placeholderIDs = po.orderItems.map(x => x.placeholderProductID).filter(y => y != null && !po.orderItems.some(x => x.productID == y));

                  placeholderIDs.forEach(pID => {
                    var eInd = po.orderItems.findIndex(x => x.placeholderProductID == pID);
                    var oItem = this.currentPolicy.orderItems.find(x => x.productID == pID);

                    if (oItem != null) {
                      po.orderItems.splice(eInd + 1, 0, {
                        id: null,
                        productID: pID,
                        isPlaceholder: true,
                        quantity: 0,
                        lineItemCode: null,
                        description: oItem.product.productName,
                        unitPrice: 0,
                        discount: 0,
                        sortNumber: oItem.product.sortNumber,
                        tags:  oItem.product.tags,
                        taxType: oItem.product.taxType,
                        placeholderProductID: null
                      });
                    }
                  });
                }
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
              this.isLoading = false;
            }
        },
        async packAndSave(po) {
          if (this.currentStockConsignment != null) {
            try {
              this.isLoading = true;
              
              //update po first
              if (updateOrderWithoutUI(po, this.currentPolicy)) {
                //save po
                var res = await this.$BlitzIt.store.patch('customer-orders', po);
                po.rowVersion = res.rowVersion;

                //pack consignment
                this.currentStockConsignment.isPacked = true;

                //patch consignment
                var consign = this.currentStockConsignment;
                res = await this.$BlitzIt.store.patch('stock-consignments', consign);
                consign.rowVersion = res.rowVersion;
                consign.packedOn = res.packedOn;
                consign.packedByUserID = res.packedByUserID;

                if (this.bladesData != null) {
                  this.bladesData.closeBlade({ bladeName: 'packing' });
                }
              }
            }
            catch (err) {
              this.msg = this.extractErrorDescription(err);
            }
            finally {
              this.isLoading = false;
            }
          }
        }
        // async pullConsignment(po) {
        //     try {
                


        //         }
                
        //     }
        //     catch (err) {
        //         this.msg = this.extractErrorDescription(err);
        //     }

        //     return po;
        // }
    }
}
</script>